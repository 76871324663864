import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Switch from 'react-switch'
import styled from 'styled-components'

import EntityList from './EntityList'
import AddCircleOutlineIcon from './Icons/AddCircleOutlineIcon'
import Title from './Title'
import FeatureValidator from '../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../authorization/features'
import { fieldNameMap } from '../ducks/meters/generation'
import type { FTEntity, FTFetchEntityList } from '../ducks/types'
import '../ducks/types'
import type { FTRouterMatch } from '../types'
import '../types'
import PanelHierarchyChart from '../pages/AccountManagement/PanelHierarchyChart'
import { colors } from '../utils/themes'
import { Paper } from '../pages/ToolsAndReports/Ernesto/ListScreen/styles'

export const AddCircleOutlineIconStyled = styled(AddCircleOutlineIcon)`
  font-size: 22px;
  margin-right: 3px;
`
export const AddPanelActionButtonStyled = styled.button`
  cursor: pointer;
  align-items: center;
  background-color: #fff;
  border: 0px;
  right: 0;

  color: #337ab7;
  display: flex;
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 0 16px;
`

const PanelControllersStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`

const TopologySwitchStyled = styled.div`
  display: flex;
  align-items: center;

  label {
    font-weight: 600;
    font-size: 15px;
    color: ${colors.blue15};
  }
`

const SwitchStyled = styled(Switch)`
  transform: scale(0.8);
`

const PanelHierarchyPaperStyled = styled(Paper)`
  margintop: 10px;
  height: 100%;
`

const PanelHierarchyChartTitleStyled = styled(Title)`
  text-align: center;
`

const PanelHierarchyCardStyled = styled.div`
  width: 100%;
`

type FTProps = {
  fetchAllPanels: FTFetchEntityList
  fetchPanels: FTFetchEntityList
  match: FTRouterMatch
  panelListEntity: FTEntity
  showModalPanelForm3: (...args: Array<any>) => any
}

const renderPanelHierarchyChart = (props) => {
  const { match } = props
  return (
    <PanelHierarchyCardStyled>
      <PanelHierarchyChartTitleStyled>
        Panel Topology
      </PanelHierarchyChartTitleStyled>
      <PanelHierarchyPaperStyled>
        <PanelHierarchyChart match={match} />
      </PanelHierarchyPaperStyled>
    </PanelHierarchyCardStyled>
  )
}

const AuthorizationErrorMsg = () => (
  <div>You do not have permission to access the panels</div>
)

const PanelsTab = (props: FTProps) => {
  const {
    panelListEntity,
    fetchAllPanels,
    fetchPanels,
    showModalPanelForm3,
    match: {
      url,
      params: { siteId },
    },
  } = props
  const [topologyState, setTopologyState] = React.useState(true)
  const fetchDefaults = {
    siteId,
  }
  const createHeader = (fieldName, displayName) => ({
    fieldName,
    displayName,
    sortable: false,
  })

  const headers = [
    createHeader('name', 'Panel Name'),
    createHeader('opportunityId', 'Panel Description'),
    createHeader('location', 'Panel Location'),
    createHeader('type', 'Panel Type'),
    createHeader('voltage', 'Panel Voltage'),
    createHeader('amperage', 'Amperage'),
    createHeader('powerSourceLevel', 'Power Source'),
    createHeader('parentPanelName', 'Upstream Panel'),
  ]

  React.useEffect(() => {
    fetchPanels(fetchDefaults)
    fetchAllPanels({
      siteId,
    })
  }, [])
  const renderTableRow = React.useCallback(
    ({
      id,
      name,
      location,
      type,
      parentPanelName,
      powerSourceLevel,
      voltage,
      amperage,
      description,
    }) => (
      <tr key={id}>
        <td>
          <Link to={`${url}/${id}`} href={`${url}/${id}`}>
            {name}
          </Link>
        </td>
        <td>{description || '--'}</td>
        <td>{location || '--'}</td>
        <td>{(type && fieldNameMap.get(type)) || '--'}</td>
        <td>{(voltage && fieldNameMap.get(voltage)) || '--'}</td>
        <td>{amperage || '--'}</td>
        <td>{powerSourceLevel || '--'}</td>
        <td>{parentPanelName || '--'}</td>
      </tr>
    ),
    [url],
  )

  const handleTopologyToggle = (value) => {
    setTopologyState(!value)
  }

  const renderPanelsTable = () => (
    <EntityList
      striped
      entity={panelListEntity}
      loadEntity={fetchPanels}
      loadEntityProps={fetchDefaults}
      tableHeaders={headers}
      renderTableRow={renderTableRow}
      showSearchBar={false}
      showPageSizer={false}
      renderNoResults={() => <div>There are no panels for this site</div>}
    />
  )
  return (
    <FeatureValidator
      requireAll={false}
      feature={AuthorizedFeatures.managePanels}
      onInvalid={AuthorizationErrorMsg}
    >
      <PanelControllersStyled>
        <TopologySwitchStyled>
          <SwitchStyled
            id='topology-switch'
            onChange={(value) => {
              handleTopologyToggle(value)
            }}
            checked={!topologyState}
          />
          <label>Topology</label>
        </TopologySwitchStyled>
        <AddPanelActionButtonStyled onClick={showModalPanelForm3}>
          <AddCircleOutlineIconStyled />
          Add Panel
        </AddPanelActionButtonStyled>
      </PanelControllersStyled>
      {!topologyState && renderPanelHierarchyChart(props)}
      {topologyState && renderPanelsTable()}
    </FeatureValidator>
  )
}

export default withRouter(PanelsTab)
